import {
  IAddress,
  IAxiosResponse,
  IProduct,
  IProductCategory,
  IProductPagination,
} from "src/models";
import axiosInstance from "./axios";

export default class ProductService {
  private static readonly url: string = "/products";
  private static readonly updatedUrl: string = "/allProducts";
  public static async findAll(
    page: number,
    size: number,
    categorydId: number,
    subCategoryIds: number[],
    searchText: string
  ): Promise<IAxiosResponse<IProductPagination>> {
    let pathUrl = `${this.updatedUrl}/page?page=${page}&size=${size}`;
    pathUrl =
      categorydId !== 0 ? `${pathUrl}&categoryId=${categorydId}` : pathUrl;
    if (subCategoryIds.length > 0) {
      subCategoryIds.forEach((c) => {
        pathUrl = `${pathUrl}&subcategoryid=${c}`;
      });
    }
    pathUrl = searchText !== "" ? `${pathUrl}&search=${searchText}` : pathUrl;
    const response = await axiosInstance.get(pathUrl);
    return response.data;
  }

  public static async findAllDiscountProducts(): Promise<
    IAxiosResponse<IProduct[]>
  > {
    const response = await axiosInstance.get("/discount?limit=8");
    return response.data;
  }
  public static async findBestSellsProducts(
    limit: number
  ): Promise<IAxiosResponse<IProduct[]>> {
    const response = await axiosInstance.get(
      `${this.url}/best-sells?limit=${limit}`
    );
    return response.data;
  }

  public static async findById(id: number): Promise<IAxiosResponse<IProduct>> {
    const response = await axiosInstance.get(`${this.url}/${id}`);
    return response.data;
  }

  public static async findRelatedProducts(
    productCategoryId: number,
    productId: number
  ): Promise<IAxiosResponse<IProduct[]>> {
    const response = await axiosInstance.get(
      `${this.url}/related?productid=${productId}&productcategoryid=${productCategoryId}`
    );
    return response.data;
  }

  public static async findDiscountProductsWithCategory(): Promise<
    IAxiosResponse<IProductCategory[]>
  > {
    const response = await axiosInstance.get(`/product/categories/discount`);
    return response.data;
  }
}
